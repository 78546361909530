.albumItem {
    width: 50%;
    border-radius: 16px; 
    padding: 0; 
    overflow: hidden; 
    position: relative; 
}

.albumItem:hover .albumInfos {
    animation: fadeIn .3s;
    opacity: 1; 
}

.albumInfos {
    position: absolute; 
    margin: 1%;
    bottom: 0vh; 
    z-index: 10;
    background-color:#000000D0;
    width: 98%; 
    opacity: 0;
    border-radius: 16px;
    animation: fadeOut .2s;
}

button.selectartistbutton  {
    z-index: 1;
    padding: 0;
    margin: 0;
    min-width: 2vmin;
    margin-left: 4px;
    /* animation: scaleOut .3s; */
}

.selectartistbutton:hover .selectartist {
    animation: scaleIn .3s;
    width: 4vh; 
    height: 4vh;
}

.selectartist {
    animation: scaleOut .3s;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; }
}

@keyframes scaleIn {
    0% { width: 3vh; height: 3vh; }
    100% { width: 4vh; height: 4vh; }
}

@keyframes scaleOut {
    0% { width: 4vh; height: 4vh; }
    100% { width: 3vh; height: 3vh; }
}