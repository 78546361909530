html, body, #root {
  height: 100%;
  overflow: hidden;
}

.image-cover {
  object-fit: cover;
  height: 105%;
}

.MuiTooltip-popper {
  position: fixed!important;
}
/* Works on Firefox */


/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 2px;
    height: 2px;
}

*::-webkit-scrollbar-track {
    background: #08082F00;
}

*::-webkit-scrollbar-thumb {
    background-color:#ffffff;
    border-radius: 20px;
    border: 2px solid #ffffff;
}

body {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
  -webkit-box-shadow: 0 0 0 30px #fff00000 inset !important;
-moz-box-shadow: 0 0 0 30px #fff00000 inset !important;
-o-box-shadow: 0 0 0 30px #fff00000 inset !important;
box-shadow: 0 0 0 30px #fff00000 inset !important;
-webkit-text-fill-color: rgb(255, 255, 255) !important;
-o-text-fill-color: rgb(255, 255, 255) !important;
-moz-text-fill-color: rgb(255, 255, 255) !important;
}

a {
  color: white;
  /* margin-right: 8px; */
}