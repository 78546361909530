.head {
    margin-top: 48px!important;
    margin-bottom: 0!important;
    padding-bottom: 0!important;
    font-family: 'Montserrat';
    font-weight: 600;
    line-height: 1.5vh;
    font-size: 1.3vh;
    padding: 0;
    margin: 0;
}

.content {
    margin-bottom: 64px!important;
    font-family: 'Montserrat';
    font-weight: 300;
    font-size: 1.3vh;
    line-height: 1.5vh;
    padding: 0;
    margin: 0;
}

.separator {
    width: 100%;
}

.avatar {
    border-radius: 0.65vh;
}

.section {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 1.6vh;
}

.selected {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 1.6vh;
}
