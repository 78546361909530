.producerItem {
    height: 55%;
    border-radius: 16px;
    padding: 0;
    overflow: hidden; 
    position: relative;
}

.producerItem:hover .producerInfos {
    animation: fadeIn .3s;
    opacity: 1; 
}

.producerInfos {
    position: absolute;
    margin: 1%;
    bottom: 0; 
    z-index: 1;
    background-color: #000000D0;
    left: 0vh;
    right: 0; 
    opacity: 0; 
    border-radius: 16px;
    animation: fadeOut .2s;
}

.producers {
    animation: fadeOut .2s;
}

.selectartistbutton  {
    z-index: 1;
    /* animation: scaleOut .3s; */
}

.selectartistbutton:hover .selectartist {
    animation: scaleIn .3s;
    width: 5vh; 
    height: 5vh;
}

.selectartist {
    animation: scaleOut .3s;
}

.producers::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; }
}

@keyframes scaleIn {
    0% { width: 4vh; height: 4vh; }
    100% { width: 5vh; height: 5vh; }
}

@keyframes scaleOut {
    0% { width: 5vh; height: 5vh; }
    100% { width: 4vh; height: 4vh; }
}