

.title {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 2vh;
    display: inline;
    color: #17043C;
}

.show_all {
    font-family: 'Montserrat'!important;
    font-weight: 200!important;
    text-decoration: underline!important;
    color: #9F9F9F;
    font-size: 1.1vh!important;
    text-transform: none!important;
    float: right;
    text-align: left;
    display: block!important;
    padding: 0!important;
    margin: 0!important;
}

.date ,
.hour ,
.place ,
.fuseau {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 2vh;
    color: #FFFFFF;
    padding: 0;
    margin: 0;
    text-align: left;
}

.time_container {
    position: absolute;
    bottom: 16px;
}

.name {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 1.1vh;
    padding: 0;
    margin: 0;
}

.content {
    font-family: 'Montserrat';
    font-weight: 300;
    font-size: 1.1vh;
    padding: 0;
    margin: 0;
}